import React from 'react';

import Home from "../pages";
import Error from "../pages/error";
import Expired from "../pages/expired";

const Survey = React.lazy(() => import("../pages/Survey"));
// const Link = React.lazy(() => import("../pages/Link"));
// const LinkShortId = React.lazy(() => import("../pages/Link/ShortId"));
// const ShortIdRedirect = React.lazy(() => import("../pages/Link/ShortIdRedirect"));
// const ShortIdApi = React.lazy(() => import("../pages/Link/ShortIdApi"));

export const ROUTE_PATHS = {
  ROOT: "/",

  // LINK: "/link",
  // LINK_SHORT_API: "/x/:shortId",
  // LINK_SHORT_API_UTM: "/x/:shortId/:utmNo",
  // LINK_SHORT_UTM: "/l/:shortId/:utmNo/:viewId",
  // LINK_SHORT_ID: "/l/:shortId/:viewId",
  // LINK_SHORT_UTM_R: "/r/:shortId/:utmNo/:viewId",
  // LINK_SHORT_ID_R: "/r/:shortId/:viewId",
  
  SURVEY_INIT: "/survey/",
  SURVEY: "/survey/:surveyId",
  SURVEY_PREVIEW: "/survey/:surveyId/preview",
  SURVEY_EXPIRED: "/survey/expired",
  
  NOT_FOUND: "/404",
  ALL: "*",
};

export const routes = [
  {
    element: <Home />,
    path: ROUTE_PATHS.ROOT,
  },
  // {
  //   element: <Link />,
  //   path: ROUTE_PATHS.LINK,
  // },
  // {
  //   element: <ShortIdApi />,
  //   path: ROUTE_PATHS.LINK_SHORT_API,
  // },
  // {
  //   element: <ShortIdApi />,
  //   path: ROUTE_PATHS.LINK_SHORT_API_UTM,
  // },
  // {
  //   element: <LinkShortId />,
  //   path: ROUTE_PATHS.LINK_SHORT_UTM,
  // },
  // {
  //   element: <LinkShortId />,
  //   path: ROUTE_PATHS.LINK_SHORT_ID,
  // },
  // {
  //   element: <ShortIdRedirect />,
  //   path: ROUTE_PATHS.LINK_SHORT_ID_R,
  // },
  // {
  //   element: <ShortIdRedirect />,
  //   path: ROUTE_PATHS.LINK_SHORT_UTM_R,
  // },
  {
    element: <Home />,
    path: ROUTE_PATHS.SURVEY_INIT,
  },
  
  {
    element: <Survey />,
    path: ROUTE_PATHS.SURVEY,
  },
  {
    element: <Survey isPreviewMode={true} />,
    path: ROUTE_PATHS.SURVEY_PREVIEW,
  },
  {
    element: <Error />,
    path: ROUTE_PATHS.NOT_FOUND,
  },
  {
    element: <Expired />,
    path: ROUTE_PATHS.SURVEY_EXPIRED,
  },
];
