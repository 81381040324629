import React, {useEffect, useState} from "react";
// import Lottie from "react-lottie";
import { useLottie } from "lottie-react";
import animationData from "../lotties/expiredSurvey.json";
import ex10Api from "../utils/Ex10Api";
import {
  Grid,
  Typography,
  Button
} from "@mui/material";

const style = {
  height: 200,
  width: 200,
};

const LoadingExpired = () => {
  const options = {
    animationData: animationData,
    loop: true,
    autoplay: true,
  };

  const { View } = useLottie(options, style);

  return View;
};


const Error = () => {
  const [channel, setChannel] = useState({});

  const handleClickLink = () => {
    if (channel.channelId) {
      window.location.replace(`https://line.me/ti/p/${channel.channelId}`)
    }
  }
  const getChannelById = async () => {
    const surveyInfo = JSON.parse(localStorage.getItem("survey-info"));
  
    try {
      const res = await ex10Api.getChannelById(surveyInfo.channelId);
      const { data } = res.data;
      if (data) {
        setChannel(data)
        console.log({ data });
      }
    } catch (err) {
      console.log("getChannelById error:", err);
    }
  };

  useEffect(() => {
    getChannelById()
  },[]);
  return (
    <>
      <Grid spacing={2} container direction="row" className="error-page-container">
        <Grid item xs={12}>
          {/* <WarningAmberIcon color="action" fontSize="large" /> */}
         <Grid justifyContent="center" container> <LoadingExpired style={{marginBottom: '-60px'}} /></Grid>
          <Typography sx={{ color: '#7d8391' }} variant="h6">กิจกรรมเสร็จสิ้นแล้ว</Typography>
          {/* <Typography sx={{ mt: 1 }} variant="h5">กิจกรรมเสร็จสิ้นแล้ว</Typography> */}
          <Typography sx={{ mt: 1, color: '#7d8391' }} variant="body1">สามารถติดตามข่าวสาร<br />และกิจกรรมได้ที่ LINE : <Button onClick={handleClickLink} style={{ textTransform: 'none', paddingLeft: 0}}>{channel.channelName}</Button></Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default Error;
