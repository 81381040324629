import React, { useEffect } from "react";
import LineUtil from "../utils/LineUtil";
import ex10Api from "../utils/Ex10Api";
import liff from "@line/liff";
import { useLottie } from "lottie-react";
import { Box } from "@mui/material";
import animationData from "../lotties/loadingBlue.json";


const styleRedirecting = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 400,
  bgcolor: "background.paper",
  borderRadius: "8px",
  p: 4,
  textAlign: "center",
};

const style = {
  height: 200,
  width: 200,
};

const LoadingBlue = () => {
  const options = {
    animationData: animationData,
    loop: true,
    autoplay: true,
  };

  const { View } = useLottie(options, style);

  return View;
};

const Home = () => {
  // const queryString = window.location.search;
  // const urlParams = new URLSearchParams(queryString);
  // const lineProfileId = urlParams.get("cid") || "";
  const str = window.location.search.substring(1);
  const params = Object.fromEntries(new URLSearchParams(str));

  function getQueryParams(url) {
    const paramArr = url.slice(url.indexOf('?') + 1).split('&');
    const params = {};
    paramArr.forEach(param => {
        const [key, val] = param.split('=');
        params[key] = decodeURIComponent(val);
    })
    return params;
}
  
  useEffect(() => {
    // eslint-disable-next-line
    async function initLiffApp() {
      try {
        const queryParams = getQueryParams(params["liff.state"]);
        console.log(queryParams)

        if (queryParams.cid) {
          const res = await ex10Api.getChannelById(queryParams?.cid);
          const { data } = res.data;
          console.log('getChannelById', data)
          
          const webLiffId = data.webLiffId
          await liff.init({ liffId: webLiffId }, async () => {
          if (liff.isLoggedIn()) {
            const profile = await liff.getProfile();
            localStorage.setItem("lineProfile", JSON.stringify(profile));
            console.log("InitLiffApp Successfully ✨🎉");
          } else {
            // liff.login({ redirectUri: encodeURIComponent(window.location.href) });
            liff.login();
          }
        }, err => console.error(err.code, err.message));
        }

      } catch (error) {
        console.log(`Error ⚠️ : ${error} `);
      }
    } 
    
    initLiffApp();

    // eslint-disable-next-line
  }, []);

  return <>
    <div
      style={{
        width: "100vw",
        height: "100vh",
      }}
    >
      <Box sx={styleRedirecting} onClick={() => {
        localStorage.clear();
        LineUtil.closeWindow();
      }}>
        <LoadingBlue />
      </Box>
    </div>
  </>;
};

export default Home;
